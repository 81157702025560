'use client';
import {
  PortableText as PortableTextToReact,
  PortableTextComponents,
} from '@portabletext/react';
import { ForwardedRef, forwardRef, useRef } from 'react';

import { cn } from '~/utils';

import configBlock from './components/block/configBlock';
import configList from './components/list/configList';
import configListItem from './components/listItem/configListItem';
import {
  makeBlockComponent,
  makeListComponent,
  makeListItemComponent,
  makeMarksComponent,
  makeTypesComponent,
} from './components/makeComponent';
import configMarks from './components/marks/configMarks';
import configTypes from './components/types/configTypes';
import styles from './PortableText.module.css';
import { PortableTextCustomProps } from './PortableText.types';

/**
 * Required Props:
 * @param value Sanity data.
 * @param options Your custom configuation for this instance of <PortableText>.
 * This is required because PortableText has no default grid position or spacing logic.
 * If you would like to use pre-existing type lockups, please look at ~/src/components/molecules/TextLockups.
 * @example Implementation example:
 * ```
 * <PortableText value={content} options={{
 *        block: {
 *         titles: {
 *          className: styles.titles,
 *          tagName: 'h1',
 *          title6: {
 *             tagName: 'span',
 *           },
 *         },
 *         accents: {
 *           className: styles.accents,
 *         },
 *         bodies: {
 *           ref: $bodies,
 *         },
 *       },
 *       types: {
 *         className: styles.types,
 *         'block.buttonGroup': {
 *           className: styles.buttons,
 *         },
 *       },
 * }} />
 * ```
 */

const PortableText = (
  props: PortableTextCustomProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    value,
    options,
    className = '',
    containerTagName = 'div',
    style,
  } = props;

  const renderClass = cn(styles.portableText, className);

  /**
   * Component Types:
   * @param block Typographic styles, grouped into accents, bodies, titles.
   * @param list HTML lists (numbered, bullets)
   * @param listItem The child of a list.
   * @param marks Inline styles (em, strong, <a> links, etc.)
   * @param types Custom block-level components (pill buttons, forms, etc.)
   */

  const components = useRef<PortableTextComponents>({
    block: makeBlockComponent(value, configBlock, options),
    list: makeListComponent(value, configList, options),
    listItem: makeListItemComponent(value, configListItem, options),
    marks: makeMarksComponent(value, configMarks, options),
    types: makeTypesComponent(value, configTypes, options),
  });
  const Wrapper = containerTagName;

  if (!Array.isArray(value) || !value) {
    return null;
  }

  return (
    <Wrapper className={renderClass} ref={ref} style={style}>
      <PortableTextToReact value={value} components={components.current} />
    </Wrapper>
  );
};

export default forwardRef(PortableText);
