'use client';

import {
  ForwardedRef,
  forwardRef,
  memo,
  useImperativeHandle,
  useRef,
} from 'react';

import PortableText from '~/components/molecules/PortableText/PortableText';
import { cn } from '~/utils';

import { PortableTextOptions } from '../PortableText/PortableTextOptions.types';
import * as lockups from './TextLockups.config';
import {
  ForwardedTextLockupRef,
  TextLockupConfig,
  TextLockupProps,
} from './TextLockups.types';

const TextLockup = (
  {
    className,
    value,
    lockupOptions = { name: 'all' },
    options: customOptions,
    blockRefs,
  }: TextLockupProps,
  ref: ForwardedRef<ForwardedTextLockupRef>,
) => {
  let options: PortableTextOptions = {};

  const lockupConfigFunction = (
    lockups as Record<string, () => TextLockupConfig>
  )[lockupOptions?.name];

  let lockupConfig: TextLockupConfig | Record<string, string> = {};
  if (lockupConfigFunction) {
    lockupConfig = lockupConfigFunction();
    options = lockupConfig.options;

    // object containg all the refs for this lockup, organized into blocks. provides a easy way to iterate through each block and access its refs.
    // if the wrapper component needs access to a this lockup's properties and refs, it will pass a blockRefs object.
    if (blockRefs?.current) {
      options.blockRefs = blockRefs;
    }
  }

  const element = useRef<HTMLDivElement>(null);

  useImperativeHandle(
    ref,
    () => ({
      $element: element,
    }),
    [],
  );

  const style: Record<string, string> = {};

  if (lockupOptions.columns) {
    style['--lockup-column-span'] = lockupOptions.columns.toString();
  }

  if (lockupOptions.textWrap) {
    style['--text-wrap'] = lockupOptions.textWrap.toString();
  }

  if (lockupOptions.alignment) {
    style['--lockup-alignment'] = lockupOptions.alignment;
    style['--lockup-justify'] =
      lockupOptions.alignment === 'left' ? 'start' : 'center';
  }

  return (
    <PortableText
      className={cn(
        className,
        lockupConfig.wrapperClass,
        lockupOptions.alignment === 'center'
          ? 'textLockupCenter'
          : 'textLockupLeft',
        lockupOptions.columns && `textLockupColumns${lockupOptions.columns}`,
      )}
      value={value}
      options={{ ...options, ...customOptions }}
      ref={element}
      style={style}
    />
  );
};
export default memo(forwardRef(TextLockup));
