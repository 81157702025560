import cloneDeep from 'lodash/cloneDeep';

import { cn, getUniqueId } from '~/utils';

import {
  ComponentRecord,
  ComponentRecordFlat,
  ComponentRecordGroup,
  ComponentRecordGrouped,
  ComponentRecordItem,
  GroupStyleKeyBlock,
  GroupStyleKeyTypes,
  PortableTextComponentKey,
  PortableTextRenderProps,
  RefArray,
  StyleKey,
} from '../PortableText.types';
import {
  PortableTextConfig,
  PortableTextConfigBlock,
  PortableTextConfigBlockType,
  PortableTextConfigFlatHierarchy,
  PortableTextConfigProp,
} from '../PortableTextConfig.types';
import {
  PortableTextOptions,
  PortableTextOptionsBlock,
  PortableTextOptionsBlockType,
  PortableTextOptionsFlatHierarchy,
} from '../PortableTextOptions.types';

/**
 * How getComponentProps works:
 * #1 Interprets our `config` props, based on custom config files (such as configBlock.tsx).
 * These `config` settings are shared globally between all PortableText instances.
 * #2 Interprets our `options` props, which are configured per-module via the `options` prop.
 * #3 Defines groups and group values (examples: titles, marks, and types).
 * #4 Compiles this information into a componentRecord to make all configurations easily traversible.
 * #5 Processes the componentRecord and select or combine props via setComponentProperties().
 * #6 Builds the final renderRecord via makeRenderProps().
 * #7 Sends the renderRecord to makeComponent, which finalizes components for @react/portabletext.
 *
 * @param type component type (block, list, listItem, marks, types)
 * @param config global config files (see /PortableText/components/block/configBlock for example)
 * @param options individual module options from the `options` prop
 * @param props JSX sent back from @react/portabletext, includes data via props.value (or props.text)
 */

const getComponentProps = (
  type: PortableTextComponentKey,
  config: PortableTextConfig,
  options: PortableTextOptions,
  props: PortableTextRenderProps,
): PortableTextRenderProps | undefined => {
  const { text, value } = props;
  /**
   * Build a record of component settings based on config and options
   */
  let componentRecord: ComponentRecord;

  /**
   * Generate `renderProps` which will be sent to makeComponent as the final output of this file.
   */
  const makeRenderProps = (
    component: ComponentRecordItem,
    styleKey: StyleKey,
    groupStyleKey?: GroupStyleKeyBlock | GroupStyleKeyTypes,
  ): PortableTextRenderProps | undefined => {
    const renderProps: PortableTextRenderProps = {
      ...props,
      _key: value?._key || getUniqueId(`${type}_`),
      className: component.className,
      tagName: props.value?.tagName || component.tagName,
      render: component.render,
      refs: component.refs,
      styleKey,
      value,
      blockRefs: options.blockRefs,
    };

    if (groupStyleKey) renderProps.groupStyleKey = groupStyleKey;

    return renderProps;
  };
  /**
   * Combine and select component properties.
   * Combine classnames from config (global), group (module-defined), and options (module-defined).
   * Choose a tagName, in order of options (module-defined), group (module-defined), and config (global).
   * Combine ref's from group (module-defined) and options (module-defined).
   */
  const setComponentProperties = (componentRecordItem: ComponentRecordItem) => {
    const componentItemProps = componentRecordItem.props;

    if (componentItemProps) {
      /**
       * Combine classnames from config (global), group (module-defined), and options (module-defined).
       */
      componentRecordItem.className = cn(
        componentItemProps.config?.className ?? '',
        componentItemProps.options?.className ?? '',
        componentItemProps.group?.className ?? '',
      );

      /**
       * Choose a tagName, in order of options (module-defined), group (module-defined), and config (global).
       */
      componentRecordItem.tagName =
        componentItemProps.options?.tagName ||
        componentItemProps.group?.tagName ||
        componentItemProps.config?.tagName ||
        'span';

      /**
       * Combine ref's from group (module-defined) and options (module-defined).
       */
      componentRecordItem.refs = [
        componentItemProps?.options?.ref,
        componentItemProps.group?.ref,
      ].filter((value) => !!value) as RefArray[];
    }
  };

  /**
   * Ensure that data (`value`) exists
   * Most data types use `value`, however marks uses `text`
   */
  if (text || value) {
    if (type === 'block') {
      /**
       * The component record compiles global config and module options and the final values.
       * Its purpose is to provide an organized source of data to select or combine data.
       * Build componentRecord for a grouped component type (block).
       * This will populate the record with configBlock values.
       */

      componentRecord = { components: {} } as ComponentRecordGrouped;

      const blockConfig = config as PortableTextConfigBlock;

      for (const [groupIterator, groupValue] of Object.entries(blockConfig)) {
        const groupKey: keyof PortableTextConfigBlock =
          groupIterator as keyof PortableTextConfigBlock;
        const group = groupValue as PortableTextConfigBlockType;

        // prepare copy
        componentRecord.components[groupKey] = { components: {} };
        const componentRecordGroup = componentRecord.components[groupKey];

        // deep clone individual values to prevent accidentally overriding config
        for (const [componentIterator, componentValue] of Object.entries(
          group,
        )) {
          const componentKey: keyof PortableTextConfigBlockType =
            componentIterator as keyof PortableTextConfigBlockType;
          const componentItem = componentValue as PortableTextConfigProp;

          // clone item properties to avoid accidental overwrites of config
          const componentItemClone = cloneDeep(
            componentItem,
          ) as ComponentRecordItem;

          // store the original props from each source
          // this allows us to combine unaltered props when sending to render
          componentItemClone.props = {
            config: componentItem,
            options: {},
            group: componentRecordGroup,
          };

          componentRecordGroup.components[componentKey] = componentItemClone;
        }
      }

      /**
       * Options are uniquely set in each individual <PortableText> implementation.
       * They take priority over config in an either/or situation.
       * Options and config may also be combined for className and ref.
       * This loop will also share group values with child items.
       */

      const blockOptions = options.block;

      if (blockOptions) {
        // loop within the exterior groups (accents, bodies, titles, for example)
        for (const [optionGroupIterator, optionGroupValue] of Object.entries(
          blockOptions,
        )) {
          if (optionGroupValue) {
            const optionGroupKey: keyof PortableTextOptionsBlock =
              optionGroupIterator as keyof PortableTextOptionsBlock;
            const optionGroup =
              optionGroupValue as PortableTextOptionsBlockType;

            // find matching componentRecord group so we can populate group values.
            const componentRecordGroupKey: keyof ComponentRecordGrouped =
              optionGroupKey as keyof ComponentRecordGrouped;

            const componentRecordGroup = componentRecord.components[
              componentRecordGroupKey
            ] as ComponentRecordGroup;

            if (typeof componentRecordGroup !== 'undefined') {
              // the componentRecord group will not have properties set by default.
              // these properties can only be set via options.
              componentRecordGroup.className = optionGroup.className;
              componentRecordGroup.tagName = optionGroup.tagName;
              componentRecordGroup.ref = optionGroup.ref;
            }
            /**
             * First, loop within all group members (eyebrow, body small, title1, for example).
             * This loop is separate because it is only populating group values to its members.
             * The values set below also creates fallbacks if these items are not specifically present in `options`.
             */
            for (const componentRecordItemQuery of Object.entries(
              componentRecordGroup.components,
            )) {
              const componentRecordItem =
                componentRecordItemQuery[1] as ComponentRecordItem;

              if (typeof componentRecordItem !== 'undefined') {
                if (componentRecordItem.props) {
                  const componentItemProps = componentRecordItem.props;

                  // combine classnames from config, group (from options), and the specific component (from options)
                  componentRecordItem.className = cn(
                    componentItemProps.config?.className ?? '',
                    componentItemProps.group?.className ?? '',
                  );

                  // choose a tagName, starting with config then override from options (if it exists)
                  componentRecordItem.tagName =
                    componentItemProps.group?.tagName ||
                    componentItemProps.config?.tagName ||
                    'span';

                  // combine refs from config, group (from options), and the specific component (from options)
                  componentRecordItem.refs = [
                    componentItemProps?.group?.ref,
                  ].filter((value) => !!value) as RefArray[];
                }
              }
            }

            /**
             * Loop within each group to find matching specific options (eyebrow, body small, title1, for example).
             * Select values based on componentRecord values of config, group, and options.
             */

            if (typeof optionGroup !== 'undefined') {
              for (const [optionIterator, optionValue] of Object.entries(
                optionGroup,
              )) {
                const optionKey: keyof PortableTextOptionsBlockType =
                  optionIterator as keyof PortableTextOptionsBlockType;

                // ensure the optionItem is a typography style and not a group property
                let optionItem;
                if (
                  optionKey !== 'className' &&
                  optionKey !== 'tagName' &&
                  optionKey !== 'ref'
                ) {
                  optionItem = optionValue as PortableTextConfigProp;
                }

                // get matching component record so we can compare our current options
                // to the component record's config settings.
                const componentRecordGroupKey: keyof ComponentRecordGrouped =
                  optionGroupKey as keyof ComponentRecordGrouped;

                const componentRecordGroup = componentRecord.components[
                  componentRecordGroupKey
                ] as ComponentRecordGroup;

                const componentRecordItem =
                  componentRecordGroup.components[optionIterator];

                if (typeof componentRecordItem !== 'undefined') {
                  if (componentRecordItem?.props) {
                    const componentItemProps = componentRecordItem.props;

                    componentItemProps.options = optionItem;

                    /**
                     * Combine or select component properties based on type and specificity.
                     */
                    setComponentProperties(componentRecordItem);
                  }
                }
              }
            }
          }
        }
      }

      // loop 3: render

      for (const [groupIterator, groupValue] of Object.entries(
        componentRecord.components,
      )) {
        const group = groupValue as ComponentRecordGroup;

        /**
         * Pre-render modification:
         * If the component type is 'normal', it needs to acquire `body`'s value.
         * 'normal' is a sanity fallback that cannot be removed via schema.
         */

        if (groupIterator === 'bodies') {
          group.components.normal = group.components.body;
        }

        // using `style` from the component's render function
        const styleKey = value?.style || '';
        const groupStyleKey = groupIterator;
        const component = group.components[styleKey];

        if (typeof component !== 'undefined') {
          return makeRenderProps(
            component,
            styleKey as StyleKey,
            groupStyleKey as GroupStyleKeyBlock | GroupStyleKeyTypes,
          );
        }
      }

      // if no component renders, error
      showComponentError(value?.style ?? '', componentRecord, props);
    } else {
      /**
       * Flat hierarchy types (marks, types, lists, listItems).
       * These component types are not double-nested into their groups like `block` types.
       * For example, a block type will be block > titles > title.
       * A mark will only be a single layer deep at marks > em.
       */

      componentRecord = { components: {} } as ComponentRecordFlat;

      // loop 1: build component config framework for a grouped component type
      const flatConfig = config as PortableTextConfigFlatHierarchy;

      // deep clone individual values to prevent overriding config
      for (const [componentIterator, componentValue] of Object.entries(
        flatConfig,
      )) {
        const componentKey: keyof PortableTextConfigFlatHierarchy =
          componentIterator as keyof PortableTextConfigFlatHierarchy;
        const componentItem = componentValue as PortableTextConfigProp;

        if (typeof componentItem !== 'undefined') {
          // clone item properties to avoid accidental overwrites of config
          const componentItemClone = cloneDeep(
            componentItem,
          ) as ComponentRecordItem;

          // store the original props from each source
          // this allows us to combine unaltered props when sending to render
          componentItemClone.props = {
            config: componentItem,
            options: {},
          };

          componentRecord.components[componentKey] = componentItemClone;
        }
      }

      /**
       * Loop 2: options
       * Options are uniquely set in each individual <PortableText> implementations.
       * They take priority over config in an either/or situation.
       * Options and config may also be combined for className and ref.
       */

      const optionKey: keyof PortableTextOptions =
        type as keyof PortableTextOptions;

      /**
       * `typeOptions` are like `group` properties.
       * Example type: `marks`, with properties `className`, `tagName`, and `ref`.
       */
      const typeOptions = options[
        optionKey
      ] as PortableTextOptionsFlatHierarchy;

      if (typeof typeOptions !== 'undefined') {
        /**
         * Loop through all componentRecord items categorized by `optionKey`.
         */
        for (const componentRecordItemIterator of Object.entries(
          componentRecord.components,
        )) {
          const componentItemKey: keyof PortableTextOptionsFlatHierarchy =
            componentRecordItemIterator[0] as keyof PortableTextOptionsFlatHierarchy;

          if (
            componentItemKey !== 'className' &&
            componentItemKey !== 'tagName' &&
            componentItemKey !== 'ref'
          ) {
            const componentRecordItem =
              componentRecord.components[componentItemKey];

            if (typeof componentRecordItem !== 'undefined') {
              if (componentRecordItem?.props) {
                /**
                 * Populate componentItemProps to make config, options, and group easily accessible.
                 */
                const componentItemProps = componentRecordItem.props;

                componentItemProps.group = {
                  className: typeOptions.className,
                  components: {},
                  tagName: typeOptions.tagName,
                  ref: typeOptions.ref,
                };

                /**
                 * Define `options` values if an `options` prop key matches the current record key.
                 */
                const optionItem =
                  typeOptions[
                    componentItemKey as keyof PortableTextOptionsFlatHierarchy
                  ];

                if (typeof optionItem !== 'undefined') {
                  componentItemProps.options =
                    optionItem as PortableTextConfigProp;
                }

                /**
                 * Combine or select component properties based on type and specificity.
                 */
                setComponentProperties(componentRecordItem);
              }
            }
          }
        }
      }

      // loop 3: render
      const styleKey =
        value?.listItem || props.markType || value?.style || value?._type || '';
      const component = componentRecord.components[styleKey];

      if (typeof component !== 'undefined') {
        return makeRenderProps(component, styleKey as StyleKey);
      }

      // if no component renders, error
      showComponentError(value?.style ?? '', componentRecord, props);
    }
  }
};

function showComponentError(
  componentKey: string,
  componentRecord: ComponentRecordFlat | ComponentRecordGrouped,
  props: PortableTextRenderProps,
) {
  console.warn(
    'Component Rendering Error componentKey=',
    componentKey,
    'componentRecord=',
    componentRecord,
    'props=',
    props,
  );
}

export default getComponentProps;
