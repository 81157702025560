import { RefObject } from 'react';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import { NavBarNavigationProps } from './Navigation.types';

type State = {
  navigationData: NavBarNavigationProps | null;
  activeMainNavItem: string | null;
  activeSubNavItem: string | null;
  isHoveringMainNavItem: boolean | null;
  isHoveringSubNavItem: boolean | null;
  currentOpenedSubNav: string | null | undefined;
  currentGroup: string | null;
  currentPageMainNavId: string | null;
  currentPageSubNavId: string | null;
  previousSubNav: string | null | undefined;
  nextSubNav: string | null | undefined;
  mobileNavOpen: boolean;
  isMobileNav: boolean;
  isReducedNav: boolean;

  // some refs are needed across multiple files so we store them here
  $el: RefObject<HTMLDivElement> | null;
  $mainNavEl: RefObject<HTMLDivElement> | null;
  $subNavEl: RefObject<HTMLDivElement> | null;
  $bannerEl: RefObject<HTMLDivElement> | null;
  $backdropEl: RefObject<HTMLDivElement> | null;
  $shadowEl: RefObject<HTMLDivElement> | null;

  // refs for mobile nav animation
  $mobileMainNavCtaItems: RefObject<RefObject<HTMLElement>[]> | null;
  $mobileBottomLinksWrapper: RefObject<HTMLDivElement> | null;

  setNavigationData: (data: NavBarNavigationProps) => void;
  setEl: (el: RefObject<HTMLDivElement>) => void;
  setMainNavEl: (el: RefObject<HTMLDivElement>) => void;
  setSubNavEl: (el: RefObject<HTMLDivElement>) => void;
  setBannerEl: (el: RefObject<HTMLDivElement>) => void;
  setMaskEl: (el: RefObject<HTMLDivElement>) => void;
  setShadowEl: (el: RefObject<HTMLDivElement>) => void;

  setMobileMainNavCtaItems: (els: RefObject<RefObject<HTMLElement>[]>) => void;
  setMobileBottomLinksWrapper: (el: RefObject<HTMLDivElement>) => void;

  setActiveMainNavItem: (value: string | null) => void;
  setActiveSubNavItem: (value: string | null) => void;
  setIsHoveringMainNavItem: (value: boolean | null) => void;
  setIsHoveringSubNavItem: (value: boolean | null) => void;
  setCurrentOpenedSubNav: (value: string | null | undefined) => void;
  setPreviousSubNav: (value: string | null | undefined) => void;
  setNextSubNav: (value: string | null | undefined) => void;
  setCurrentGroup: (value: string | null) => void;
  setCurrentPageMainNavId: (value: string | null) => void;
  setCurrentPageSubNavId: (value: string | null) => void;
  setMobileNavOpen: (value: boolean) => void;
  setIsMobileNav: (value: boolean) => void;
  setIsReducedNav: (value: boolean) => void;
};

const NavState = createWithEqualityFn<State>()(
  (set) => ({
    navigationData: null,
    activeMainNavItem: null,
    activeSubNavItem: null,
    isHoveringMainNavItem: false,
    isHoveringSubNavItem: false,
    currentOpenedSubNav: null,
    currentGroup: null,
    currentPageMainNavId: null,
    currentPageSubNavId: null,
    previousSubNav: null,
    nextSubNav: null,
    mobileNavOpen: false,
    isMobileNav: true,
    isReducedNav: false,

    $el: null,
    $mainNavEl: null,
    $subNavEl: null,
    $bannerEl: null,
    $backdropEl: null,
    $shadowEl: null,

    // refs for mobile nav animation
    $mobileMainNavCtaItems: null,
    $mobileBottomLinksWrapper: null,

    setNavigationData: (data: NavBarNavigationProps) =>
      set({ navigationData: data }),

    setEl: (el: RefObject<HTMLDivElement>) => set({ $el: el }),
    setMainNavEl: (el: RefObject<HTMLDivElement>) => set({ $mainNavEl: el }),
    setSubNavEl: (el: RefObject<HTMLDivElement>) => set({ $subNavEl: el }),
    setBannerEl: (el: RefObject<HTMLDivElement>) => set({ $bannerEl: el }),
    setMaskEl: (el: RefObject<HTMLDivElement>) => set({ $backdropEl: el }),

    setShadowEl: (el: RefObject<HTMLDivElement>) => set({ $shadowEl: el }),

    setMobileMainNavCtaItems: (els: RefObject<RefObject<HTMLElement>[]>) => {
      set({ $mobileMainNavCtaItems: els });
    },
    setMobileBottomLinksWrapper: (el: RefObject<HTMLDivElement>) => {
      set({ $mobileBottomLinksWrapper: el });
    },

    setActiveMainNavItem: (value: string | null) =>
      set({ activeMainNavItem: value }),
    setActiveSubNavItem: (value: string | null) =>
      set({ activeSubNavItem: value }),
    setIsHoveringMainNavItem: (value: boolean | null) =>
      set({ isHoveringMainNavItem: value }),
    setIsHoveringSubNavItem: (value: boolean | null) =>
      set({ isHoveringSubNavItem: value }),
    setCurrentOpenedSubNav: (value: string | null | undefined) =>
      set({ currentOpenedSubNav: value }),
    setPreviousSubNav: (value: string | null | undefined) =>
      set({ previousSubNav: value }),
    setNextSubNav: (value: string | null | undefined) =>
      set({ nextSubNav: value }),
    setCurrentGroup: (value: string | null) => set({ currentGroup: value }),
    setCurrentPageMainNavId: (value: string | null) =>
      set({ currentPageMainNavId: value }),
    setCurrentPageSubNavId: (value: string | null) =>
      set({ currentPageSubNavId: value }),
    setMobileNavOpen: (value: boolean) => set({ mobileNavOpen: value }),
    setIsMobileNav: (value: boolean) => set({ isMobileNav: value }),
    setIsReducedNav: (value: boolean) => set({ isReducedNav: value }),
  }),
  shallow,
);

export default NavState;
